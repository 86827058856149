import { Input as UnityInput } from "@pricefx/unity-components";
import { Input as AntdInput } from "antd";
import cx from "classnames";
import PropTypes from "prop-types";
import React from "react";
import "./Input.style.less";

export const Input = ({ className, ...rest }) => {
    return (
        <UnityInput
            size="medium"
            className={cx("pmInput", className)}
            {...rest}
        />
    );
};

Input.Group = AntdInput.Group;
Input.Password = AntdInput.Password;
Input.Search = AntdInput.Search;
Input.TextArea = AntdInput.TextArea;

Input.propTypes = {
    className: PropTypes.string,
};
